/*
    Pull in the splide core files via npm
*/
@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}
.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}
.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}
.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}
.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}
.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s infinite linear;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle__pause {
  display: none;
}
.splide__toggle.is-active .splide__toggle__play {
  display: none;
}
.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

/*
    Custom styles
*/
/*
    You can see a clear demo of use/forwards here...
    https://www.youtube.com/watch?v=CR-a8upNjJ0&t=286s
*/
/* -----------------------------
File Path
-------------------------------- */
/* -----------------------------
Spacing
-------------------------------- */
/* -----------------------------
style variables
-------------------------------- */
/* -----------------------------
Header
-------------------------------- */
/* -----------------------------
Page
-------------------------------- */
/*
    Breakpoint variables
*/
/*
    Variable Example
*/
/*
    Breakpoint Mixins
*/
/*
    Joint hover and Breakpoint Mixin
*/
/* ------------------------------------------------------
**** Fonts
------------------------------------------------------ */
/* ---- Webfonts ---- */
/*

Source Sans Pro
Google fonts
font-family: 'Source Sans Pro', sans-serif;

DIN Next
Fonts.com
font-family: 'DIN Next W01', sans-serif;

Neue Helvetica
Fonts.com
font-family: 'Neue Helvetica W01', sans-serif;

Montserrat
Field labels

*/
:root {
  --body-font-size: 1.5rem;
  --body-line-height: 1.5;
  --large-font-size: 1.6rem;
  --h1-font-size: 2rem;
  --h1-line-height: 1.2;
  --h2-font-size: 1.8rem;
  --h2-line-height: 1.2;
  --h3-font-size: 1.8rem;
  --h3-line-height: 1.2;
  --h4-font-size: 1.8rem;
  --h4-line-height: 1.2;
  --h5-font-size: 1.7rem;
  --h5-line-height: 1.2;
  --h6-font-size: 1.7rem;
  --h6-line-height: 1.2;
  /* [02] */
  --giga-font-size: 1.8rem;
  --mega-font-size: 4.4rem;
  --kilo-font-size: 4rem;
  --milli-font-size: 1.3rem;
  --centi-font-size: 1.4rem;
}

@media screen and (min-width: 22.5em) {
  :root {
    --h1-font-size: 2.2rem;
    --h2-font-size: 2rem;
    --h3-font-size: 1.9rem;
    --giga-font-size: 2.8rem;
  }
}
@media screen and (min-width: 39em) {
  :root {
    --giga-font-size: 4.3rem;
  }
}
@media screen and (min-width: 48em) {
  :root {
    --body-font-size: 1.6rem;
    --large-font-size: 1.8rem;
    --h1-font-size: 2.6rem;
    --h2-font-size: 2.2rem;
  }
}
@media screen and (min-width: 62em) {
  :root {
    --body-font-size: 1.8rem;
    --large-font-size: 2rem;
    --h1-font-size: 3rem;
    --h2-font-size: 2.8rem;
    --giga-font-size: 6.7rem;
  }
}
@media screen and (min-width: 75em) {
  :root {
    --h1-font-size: 4rem;
    --h2-font-size: 3.2rem;
    --h3-font-size: 1.9rem;
    --h4-font-size: 1.8rem;
    --h5-font-size: 1.7rem;
    --h6-font-size: 1.7rem;
    --giga-font-size: 7.3rem;
  }
}
/*
    Basic system font stacks
*/
/*
    Brand fonts
*/
/*
    Font stacks
*/
/*
    Font Family key for Fractal
*/
/* Weights */
/* Size */
/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */
/*
    Font Mixins
*/
/* ----- Brand colours ----- */
/* ------------------------------------------------------
**** Animations
------------------------------------------------------ */
/* ---- Mobile menu button ---- */
@-webkit-keyframes mobileMenuBtnTopClose {
  0% {
    -ms-transform: translate(0, 9px) rotate(45deg);
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
  50%, 70% {
    -ms-transform: translate(0, 9px);
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes mobileMenuBtnTopClose {
  0% {
    -ms-transform: translate(0, 9px) rotate(45deg);
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
  50%, 70% {
    -ms-transform: translate(0, 9px);
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes mobileMenuBtnTopOpen {
  0% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -ms-transform: translate(0, 9px);
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -ms-transform: translate(0, 9px) rotate(45deg);
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
}
@keyframes mobileMenuBtnTopOpen {
  0% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -ms-transform: translate(0, 9px);
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -ms-transform: translate(0, 9px) rotate(45deg);
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
}
@-webkit-keyframes mobileMenuBtnBottomClose {
  0% {
    -ms-transform: translate(0, -9px) rotate(-45deg);
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
  50%, 70% {
    -ms-transform: translate(0, -9px);
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes mobileMenuBtnBottomClose {
  0% {
    -ms-transform: translate(0, -9px) rotate(-45deg);
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
  50%, 70% {
    -ms-transform: translate(0, -9px);
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes mobileMenuBtnBottomOpen {
  0% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -ms-transform: translate(0, -9px);
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -ms-transform: translate(0, -9px) rotate(-45deg);
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
}
@keyframes mobileMenuBtnBottomOpen {
  0% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -ms-transform: translate(0, -9px);
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -ms-transform: translate(0, -9px) rotate(-45deg);
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
}
@-webkit-keyframes mobileMenuBtnMiddleClose {
  0%, 40% {
    opacity: 0;
  }
  50%, 100% {
    opacity: 1;
  }
}
@keyframes mobileMenuBtnMiddleClose {
  0%, 40% {
    opacity: 0;
  }
  50%, 100% {
    opacity: 1;
  }
}
@-webkit-keyframes mobileMenuBtnMiddleOpen {
  0%, 40% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}
@keyframes mobileMenuBtnMiddleOpen {
  0%, 40% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}
/* Fade in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* ---- Content Entry ---- */
/* Fade in down */
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}
/* Fade in up */
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* Fade in up margin */
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
/* Grow in fade */
@keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/* ---- Highlight pulse ---- */
@keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
/* ---- Highlight pulse with outline ---- */
@keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
.no-js .splide {
  visibility: inherit !important;
}

.splide:not(.is-overflow) .splide__arrows {
  display: none;
}

.splide__arrow {
  position: absolute;
  top: calc(50% - (var(--h1-font-size) + 32px) / 2);
  height: calc(var(--h1-font-size) + 32px);
  border: 0;
  background: transparent;
  cursor: pointer;
  padding-block: 16px;
  padding-inline: 0;
  display: grid;
  place-items: center;
  z-index: 3;
}
.splide__arrow:before {
  content: "";
  position: absolute;
  background: transparent;
  border-radius: 100%;
  left: 0;
  top: calc(50% - (var(--h1-font-size) + 32px) / 2);
  right: 0;
  bottom: 0;
  height: calc(var(--h1-font-size) + 32px);
  width: calc(var(--h1-font-size) + 32px);
  transition: background 250ms ease-in-out;
}
.splide__arrow:after {
  content: "\e908";
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #ffffff;
  font-size: var(--h1-font-size);
  z-index: 1;
  transition: transform 250ms ease-in-out;
}
@media screen and (min-width: 48em) {
  .splide__arrow:after {
    color: #2c364a;
  }
}
.splide__arrow:hover:before, .splide__arrow:focus:before, .splide__arrow:active:before {
  background: rgba(40, 47, 64, 0.95);
}
@media screen and (min-width: 48em) {
  .splide__arrow:hover:before, .splide__arrow:focus:before, .splide__arrow:active:before {
    background: rgba(255, 255, 255, 0.95);
  }
}
@media screen and (min-width: 48em) {
  .splide__arrow:hover:after, .splide__arrow:focus:after, .splide__arrow:active:after {
    color: #0073BC;
  }
}
.splide__arrow--prev {
  left: 0;
  right: auto;
}
@media screen and (min-width: 48em) {
  .splide__arrow--prev {
    left: auto;
    right: 100%;
  }
}
.splide__arrow--prev:before {
  right: auto;
  left: -100%;
}
@media screen and (min-width: 48em) {
  .splide__arrow--prev:before {
    left: -50%;
  }
}
.splide__arrow--prev:after {
  transform: rotate(180deg);
}
.splide__arrow--prev:hover:after {
  transform: rotate(180deg) translateX(2px);
}
.splide__arrow--next {
  right: 0;
  left: auto;
}
@media screen and (min-width: 48em) {
  .splide__arrow--next {
    left: 100%;
    right: auto;
  }
}
.splide__arrow--next:before {
  left: -50%;
}
@media screen and (min-width: 48em) {
  .splide__arrow--next:before {
    right: -50%;
    left: auto;
  }
}
.splide__arrow--next:hover:after {
  transform: translateX(2px);
}
.splide__pagination {
  gap: 16px;
}
.splide__pagination__page {
  cursor: pointer;
  background-color: #b8b8b8;
  border: 0;
  border-radius: 200px;
  width: 12px;
  height: 12px;
  transition: background 250ms ease-in-out;
}
.splide__pagination__page.is-active {
  background-color: #2c364a;
}
.splide__pagination__page:hover, .splide__pagination__page:focus, .splide__pagination__page:active {
  background-color: #696d73;
}