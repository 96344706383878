/*
    Pull in the splide core files via npm
*/
@use "_splide-core" as *;



/*
    Custom styles
*/

@use "../../00-settings" as *;


// Make sure the banners still load if no js loads
.no-js .splide {
    visibility: inherit !important;
}


// don't know why this isn't part of core but pagination is.
.splide:not(.is-overflow) .splide__arrows {
    display: none;
}

.splide {
    &__arrows {
    }

    &__arrow {
        position: absolute;
        top: calc(50% - ((var(--h1-font-size) + $spacing*4) / 2));
        height: calc(var(--h1-font-size) + $spacing*4);
        // transform: translateY(-50%);
        border: 0;
        background: transparent;
        cursor: pointer;
        padding-block: $spacing*2;
        padding-inline: 0;
        display: grid;
        place-items: center;
        z-index: 3;

        &:before {
            content: "";
            position: absolute;
            background: transparent;
            border-radius: 100%;
            left: 0;
            top: calc(50% - ((var(--h1-font-size) + $spacing*4) / 2));
            right: 0;
            bottom: 0;
            height: calc(var(--h1-font-size) + $spacing*4);
            width: calc(var(--h1-font-size) + $spacing*4);
            transition: background $link-transition;
        }

        &:after {
            content: "\e908";
            font-family: icomoon!important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            color: $color-white;
            font-size: var(--h1-font-size);
            z-index: 1;
            transition: transform $link-transition;

            @include screen($bp768) {
                color: $color-00;
            }
        }

        &:hover,
        &:focus,
        &:active {
            &:before {
                background: rgba($color-00-dark, 0.95);

                @include screen($bp768) {
                    background: rgba($color-white, 0.95);
                }
            }

            &:after {
                @include screen($bp768) {
                    color: $color-01;
                }
            }
        }

        &--prev {
            left: 0;
            right: auto;

            @include screen($bp768) {
                left: auto;
                right: 100%;
            }

            &:before {
                right: auto;
                left: -100%;

                @include screen($bp768) {
                    left: -50%;
                }
            }

            &:after {
                transform: rotate(180deg);
            }

            &:hover {
                &:after {
                    transform: rotate(180deg) translateX(2px);
                }
            }
        }

        &--next {
            right: 0;
            left: auto;

            @include screen($bp768) {
                left: 100%;
                right: auto;
            }

            &:before {
                left: -50%;

                @include screen($bp768) {
                    right: -50%;
                    left: auto;
                }
            }

            &:hover {
                &:after {
                    transform: translateX(2px);
                }
            }

        }
    }

    &__pagination {
        gap: $spacing*2;

        &__page {
            cursor: pointer;
            background-color: $color-grey-04;
            border: 0;
            border-radius: 200px;
            width: $spacing*1.5;
            height: $spacing*1.5;
            transition: background $link-transition;

            &.is-active {
                background-color: $color-00;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: $color-grey-07
            }
        }
    }
}
